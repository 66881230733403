import React, { useState } from "react"
import { Link } from "gatsby"
import Paradise from "../images/Paradise_logo.svg"
import YouTube from "react-youtube"
import VisualOff from "../images/visual_off.svg"
import VisualOn from "../images/asset2.svg"
import AudioOn from "../images/asset3.svg"
import AudioOff from "../images/asset4.svg"
import { Seo } from "../components/seo"
import { graphql } from "gatsby"

let player = null

const IndexPage = ({ data }) => {
  const [open, setOpen] = useState(false)
  const [showVideo, setShowVideo] = useState(true)
  const [hideLoading, setHideLoading] = useState(true)
  const [muted, setMuted] = useState(true)
  const { bg_color, bg_youtube, lookbooks } = data.sanitySiteSettings
  const onReady = (e) => {
    player = e
    play()
  }
  const play = () => {
    if (player) player.target.playVideo()
    setTimeout(() => {
      setHideLoading(false)
    }, 1000)
  }
  const mute = () => {
    if (player) player.target.mute()
  }
  const unMute = () => {
    if (player) player.target.unMute()
  }
  const handleMute = () => {
    if (muted) unMute()
    else mute()
    setMuted(!muted)
  }
  const dropdownClass =
    `homepage-dropdown ` + (open ? "homepage-dropdown__open" : "fadeout hidden")
  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <div
      className="page no-select home-page"
      style={{ backgroundColor: bg_color }}
    >
      <Seo
        title="PRD | PARADISE4SAIGON"
        keywords={[`paradise4saigon`, "prd shop", "prd vn", "paradise saigon"]}
      />
      <div
        style={{
          position: "absolute",
          pointerEvents: "none",
          opacity: hideLoading ? 0 : 1,
          display: showVideo ? "block" : "none",
          top: 0,
          left: 0,
          zIndex: 0,
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <YouTube
            className="youtube-container-index"
            videoId={bg_youtube}
            opts={{
              playerVars: {
                autoplay: 1,
                playsinline: 1,
                iv_load_policy: 3,
                rel: 0,
                mute: 1,
                // mute: 0,
                loop: 1,
                playlist: bg_youtube,
                controls: 0,
                modestbranding: 1,
              },
            }}
            onReady={onReady}
          />
        </div>
      </div>
      <div className="homepage-logo d-flex align-items-center">
        <div style={{ width: 150, height: 34, position: "relative" }}>
          <button onClick={handleClick}>
            <Paradise width="150px" />
          </button>
          <button
            className="round-button clickable"
            aria-label="Open Menu"
            style={{
              position: "absolute",
              right: "-30px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            onClick={handleClick}
          ></button>
        </div>

        <div className="homepage-dropdown-wrapper">
          <div className={dropdownClass}>
            <Link to={`/lookbook/${lookbooks[0].slug.current}`}>Lookbook*</Link>
            {/* <Link to={`/lookbook`}>Lookbook</Link> */}
            <Link to="/redirect">Web*Store</Link>
            <Link to="/article">Article*</Link>
            <Link to="/about">About</Link>
            <Link to="/tv">Para*TV</Link>
            {/* <Link to="/stockist">Stockist</Link> */}
          </div>
        </div>
      </div>
      <div className="home-page-bottom">
        <button
          className="home-page-control visual-icons visual"
          onClick={() => setShowVideo(!showVideo)}
        >
          <div
            style={{
              display: "flex",
              width: 100,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {showVideo ? (
              <VisualOn className="visual-icon clickable" />
            ) : (
              <VisualOff className="visual-icon clickable" />
            )}
            <div className="button-text">VISUAL {showVideo ? "ON" : "OFF"}</div>
          </div>
        </button>
        {/* <LanguageButtons /> */}
        <a href="https://vi.paradise4saigon.com/shop">VIỆT NAM</a>
        <span style={{ margin: 10 }}>/</span>
        <a href="https://paradise4saigon.com/shop">WORLDWIDE</a>
        <button
          className="home-page-control mute visual-icons"
          onClick={handleMute}
        >
          <div
            style={{
              display: "flex",
              width: 100,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {muted ? (
              <AudioOff className="visual-icon clickable" />
            ) : (
              <AudioOn className="visual-icon clickable" />
            )}
            <div className="button-text">AUDIO {muted ? "OFF" : "ON"}</div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query {
    sanitySiteSettings {
      bg_color
      bg_youtube
      lookbooks {
        slug {
          current
        }
      }
    }
  }
`
